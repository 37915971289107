import React from 'react'
import { Link } from 'react-router-dom';

const StoresLandingPage = () => {
    return (
        <>
            <div id="plant" className="section product" style={{ paddingTop: '40px', marginBottom: '-45px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingBottom: '65px', marginLeft: '0%' }}>
                                <h2 className="black" style={{ color: 'black', fontFamily: 'Assistant', fontWeight: 'bolder' }}>OUR STORES</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="clothes_main section" style={{ paddingBottom: '30px', paddingTop: '0px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="sport_product">
                                <Link to="/stores" >
                                    <figure><img loading="lazy" src={process.env.PUBLIC_URL + '/images/retail-shop.jpeg'} height="12em !important" alt="img" /></figure>
                                    <h4 style={{ fontWeight: 'bold' }}>Retail Shop</h4>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                            <div className="sport_product">
                                <Link to="/stores" >
                                    <figure><img loading="lazy" src={process.env.PUBLIC_URL + '/images/dine.jpeg'} height="12em" alt="img" /></figure>
                                    <h4 style={{ fontWeight: 'bold' }}>Dine</h4>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                            <div className="sport_product">
                                <Link to="/stores" >
                                    <figure><img loading="lazy" src={process.env.PUBLIC_URL + '/images/commercial-space.jpeg'} height="12em" alt="img" /></figure>
                                    <h4 style={{ fontWeight: 'bold' }}>Commercial Space</h4>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <div className="sport_product">
                                <Link to="/stores" >
                                    <figure><img loading="lazy" src={process.env.PUBLIC_URL + '/images/office-space.jpeg'} height="12em" alt="img" /></figure>
                                    <h4 style={{ fontWeight: 'bold' }}>Office Space</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12" style={{ textAlign: 'center', marginTop: '20px' }}>
                        <Link className="btn btn-lg btn-primary" style={{ fontWeight: 'bold' }} to="/stores" role="button" >
                            ALL STORES <i className="fa fa-arrow-right" aria-hidden="true"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StoresLandingPage