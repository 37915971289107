import React from 'react'

const insideViewStyle = {
    paddingTop: '20px',
    textAlign: 'center',
    paddingBottom: '50px'
}

const CbnMallMap = () => {
    return (
        <>
            <div className="section" >
                <div className="inside_view" style={insideViewStyle}>
                    <h2 style={{ color : 'black'}}>Take a tour inside <br /><span className="ambienceinsidehead"><span className="red">CBD</span> Mall</span></h2>
                    <div className="map">
                        <div className="ggn_map">
                            <iframe title="cbdmall" src="https://www.google.com/maps/embed?pb=!4v1537879017608!6m8!1m7!1sdyB-UdCZi5IojJdhXJ7lvw!2m2!1d28.50301138383648!2d77.09723105960502!3f173.77!4f-2.930000000000007!5f0.4000000000000002" className="insidemap" height="600px" style={{ border: '0', minWidth: '90%'}} ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CbnMallMap
