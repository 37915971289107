import React from 'react'

const CopyRightFooter = () => {
    return (
        <div id="plant" className="footer layout_padding">
            <div className="container" style={{ padding: '0px', margin: '0px' }}>
                <div className="row">
                    <div className="col-12" style={{ textAlign: 'center' }}>
                        <div className="copyright" style={{ textAlign: "center", backgroundColor : 'white', color:'black' }}>
                        ©2021 CBD Mall Suraj Kund, All Rights Reserved By Uchoudhary.<br />
                            <span className="codepenters">Powered by : <a style={{ color: 'black' }} href="mailto:nikhilsmn2@gmail.com" alt="mail" >Nikhil & Abhishek</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CopyRightFooter
