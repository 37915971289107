import React from 'react'

import StoresLandingPage from './StoreLandingPage'
import HighLights from './HighLights'
import CbnMallMap from './CbnMallMap'
import './css/Home.css'
import GuestAminities from './GuestAminities'
import Helmet from 'react-helmet';
import {METADATA_PAGES} from '../MetaData';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>{METADATA_PAGES.Home.title}</title>
                <meta title={METADATA_PAGES.Home.title} name="description" content={METADATA_PAGES.Home.content} />
                <meta title={METADATA_PAGES.Home.title} name="keywords" content={METADATA_PAGES.Home.keywords} />
            </Helmet>
            <StoresLandingPage />
            <HighLights />
            <CbnMallMap />
            <GuestAminities />
        </>
    );
}

export default Home