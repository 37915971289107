import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'

const FooterMainStyle = {
    float: 'left',
    marginBottom: '36px'
}
const subscribeStyle = {
    borderTopRightRadius: '20px !important',
    borderBottomRightRadius: '20px !important',
    color: '#ffffff',
    paddingBottom: '10px',
    paddingTop: '10px',
    backgroundColor: 'purple'
}


export class Footer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: ''
        }
    }

    componentDidMount() {

    }

    handlerChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = e => {
        e.preventDefault();
        var obj = {
            email: this.state.email,
            purpose: 'NEWSLETTER'
        }

        axios.post("https://cbd39.com/api/savedata.php", obj)
            .then(response => {
                console.log(response);
                this.setState({
                    email: ''
                });
                localStorage.setItem("session_email", obj.email);
                document.getElementById("newsletter-form-success").style.display = "block";
                setTimeout(()=> {
                    document.getElementById("newsletter-form-success").style.display = "none";
                }, 2000);
                document.getElementById("newsletter_loader").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                document.getElementById("newsletter-form-warning").style.display = "block";
                setTimeout(()=> {
                    document.getElementById("newsletter-form-warning").style.display = "none";
                }, 2000);
                document.getElementById("newsletter_loader").style.display = "none";
            });
    }

    render() {
        const { email } = this.state;
        return (
            <div id="footer" className="Address layout_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="titlepage">
                                <div className="main" style={FooterMainStyle}>
                                    <h1 className="address_text">Address</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6" style={{ lineHeight: '120px' }}>
                            <div className="col-lg-12" style={{ textAlign: 'center' }} >
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/ashish.ashsih.100" style={{ padding: '0px 5px' }}>
                                    <img loading="lazy"src={process.env.PUBLIC_URL + '/images/social-icon/facebook.png'} width="45" height="55" alt="img" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://instagram.com/cbd039?igshid=ydhda259x3o6" style={{ padding: '0px 10px' }} >
                                    <img loading="lazy"src={process.env.PUBLIC_URL + '/images/social-icon/instagram.png'} width="45" height="55" alt="img" />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/reena-shah-00692420a" style={{ padding: '0px 10px' }} >
                                    <img loading="lazy"src={process.env.PUBLIC_URL + '/images/social-icon/linkedin.png'} width="45" height="55" alt="img" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="address_2">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="site_info">
                                    <span className="info_icon"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/map-icon.png'} alt="icon" /></span>
                                    <span style={{ marginTop: '10px' }} > Near Taj Hotel ( Claridges Hotel ), SurajKund Road, Sector 39, Faridabad Road</span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="site_info">
                                    <span className="info_icon"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/phone-icon.png'} alt="icon" /></span>
                                    <span style={{ marginTop: '21px' }}>( +91 98104-37024 )</span>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-4">
                                <div className="site_info">
                                    <span className="info_icon"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/email-icon.png'} alt="icon" /></span>
                                    <span style={{ marginTop: '21px' }} >info@cbd39.com</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="layout_padding" style={{ padding: '30px 0px', marginLeft: '20%' }} >
                            <div className="row" style={{ paddingLeft: '10px' }}>
                                <div className="col-md-4">
                                    <h1 style={{ paddingTop: '35px', fontSize: '40px' }} >Subscribe Our Newsletter</h1>
                                    <p style={{ display: 'none' }} className="long_text">It is a long established fact that a reader will be distracted a</p>
                                </div>
                                <div className="col-md-8">
                                    <div className="email_text">
                                        <form style={{ backgroundColor: '#f5f5dc' }} onSubmit={this.handleSubmit}>
                                            <div className="input-group mb-3">
                                                <input style={{ borderBottomLeftRadius: '20px !important', borderTopLeftRadius: '20px !important' }} type="email"
                                                    className="form-control" placeholder="Email" name="email" value={email} onChange={this.handlerChange} required />

                                                <div className="input-group-append">
                                                    <button type="submit" style={subscribeStyle} className="btn btn-primary">Subscribe</button>
                                                </div>
                                            </div>
                                            <img loading="lazy"id="newsletter_loader" style={{ display: 'none', float: 'right' }} src="images/loading.gif" width="40" height="40" alt="gif" />
                                        </form>
                                        <div className="alert alert-success" id="newsletter-form-success" style={{ display: 'none' }} >
                                            <strong>Success!</strong> Request Has been Made Successfully, Our Team will Contanct you shortly.
                                            </div>
                                        <div className="alert alert-warning" id="newsletter-form-error" style={{ display: 'none' }} >
                                            <strong>Error!</strong> There are something wrong with the server.
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ paddingBottom: '40px' }} >
                    <div className="col-lg-12" style={{ textAlign: 'center' }} >
                        <Link className="btn btn-lg btn-primary copyright-footer-btn" to="/floor-plan" role="button" >
                            FLOOR PLAN
                    </Link>
                        <Link className="btn btn-lg btn-primary copyright-footer-btn" to="/contact" role="button" >
                            CONTACT US
                    </Link>
                        <Link className="btn btn-lg btn-primary copyright-footer-btn" to="/stores" role="button" >
                            STORES
                    </Link>
                        {/* <a className="btn btn-lg btn-primary" style={{ fontWeight: 'bold', backgroundColor: 'white', color: 'black' }} href="/" role="button">EXPLORE</a>
                    <a className="btn btn-lg btn-primary" style={{ fontWeight: 'bold', backgroundColor: 'white', color: 'black' }} href="about.html" role="button">CONTACT US </a>
                    <a className="btn btn-lg btn-primary" style={{ fontWeight: 'bold', backgroundColor: 'white', color: 'black' }} href="about.html" role="button">FLOOR PLAN </a> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer
