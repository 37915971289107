import React from 'react'

const GuestAminities = () => {
    return (
        <>
            <div className="section layout_padding padding-top_0">
                <div className="container" style={{ maxWidth: '100%' }}>
                    <div className="row" >
                        <div className="col-sm-12">
                            <div id="testimonial" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner" style={{ minHeight : '100px' }}>
                                    <div className="carousel-item active">
                                        <div className="titlepage">
                                            <div>
                                                <img loading="lazy" width='100%' style={{ maxHeight: '600px' }} src={process.env.PUBLIC_URL + '/images/guest_aminities.jpg'} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GuestAminities
