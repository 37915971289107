import React from 'react'
import Helmet from 'react-helmet'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './css/Stores.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import { METADATA_PAGES } from '../MetaData'

const storesImagesList = [
    "/images/shops/GFloor/cbd-ground-floor-atm1.jpg",
    "/images/shops/GFloor/cbd-ground-floor-atm2.jpg",
    "/images/shops/GFloor/cbd-ground-floor-lobby.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-1.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-2.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-3.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-4.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-5.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-6.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-7.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-8.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-9.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-10.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-11.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-12.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-12a.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-14.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-15.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-16.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-17.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-18.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-19.jpg",
    "/images/shops/GFloor/cbd-ground-floor-shop-20.jpg",
];
const firstFloorStoresImagesList = [
    "/images/shops/FirstFloor/cbd-first-floor-accelerator.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-1.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-2.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-3.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-4.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-6.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-7.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-8.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-9.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-10.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-11.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-12.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-12a.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-14.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-15.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-16.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-17.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-18.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-19.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-20.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-20a.jpg",
    "/images/shops/FirstFloor/cbd-first-floor-shop-20b.jpg"
];
const secondFloorStoresImagesList = [
    "/images/shops/SecondFloor/cbd-second-floor-lift.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-1.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-2.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-3.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-4.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-5.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-6.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-7.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-8.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-9.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-10.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-11.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-12.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-12a.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-14.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-15.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-16.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-17.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-18.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-19.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-20.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-21.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-22.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-23.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-24.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-25.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-26.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-27.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-28.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-29.jpg",
    "/images/shops/SecondFloor/cbd-second-floor-office-30.jpg",
];
const thirdFloorStoresImagesList = [
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-lift.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-1.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-2.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-3.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-4.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-5.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-6.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-7.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-8.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-9.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-10.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-11.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-12.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-12a.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-14.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-15.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-16.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-17.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-18.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-19.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-20.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-21.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-22.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-23.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-24.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-25.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-26.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-27.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-28.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-29.jpg",
    "/images/shops/ThirdFloor/cbd-faridabad-3rd-floor-30.jpg",
];

const Stores = () => {
    return (
        <>
            <Helmet>
                <title>{METADATA_PAGES.Store.title}</title>
                <meta title={METADATA_PAGES.Store.title} name="description" content={METADATA_PAGES.Store.content} />
                <meta title={METADATA_PAGES.Store.title} name="keywords" content={METADATA_PAGES.Store.keywords} />
            </Helmet>
            <div id="plant" className="section product" style={{ paddingTop: '40px' }} >
                <div className="container">
                <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingBottom: '30px', marginLeft: '0%' }}>
                                <h2 style={{ color: 'black' }} >STORES LIST</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingLeft : '20px', paddingBottom: '0px', marginLeft: '0%', float : 'left' }}>
                                <h2 style={{ color: 'purple', fontSize : '35px' }} >GROUND FLOOR : </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="plant" className="section product mobile-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Carousel showArrows={true} autoFocus={true} autoPlay={true} >
                        {storesImagesList.map(url => (
                            <div>
                            <img loading="lazy"src={url} width="200" height="200" alt="img01" />
                            {/* <p className="legend">Legend 2</p> */}
                            </div>
                            ))}
                    </Carousel>
                </div>
            </div>
            <div id="plant" className="section product web-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Splide options={ {
                            type : 'loop',
                            gap: '10px',
                            speed : 200,
                            perPage : 4,
                            perMove : 1,
                            easing : 'linear',
                            autoplay : true,
                            pauseOnHover : false,
                            resetProgress: false,
                            arrows: 'slider',
                        } }
                        hasSliderWrapper
                        
                        hasAutoplayProgress>
                        {storesImagesList.map(url => (
                           <SplideSlide>
                           <img loading="lazy"src={url} width="400" height="400" alt="img01" />
                          </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </div>
            <div id="plant" className="section product" style={{ paddingTop: '40px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingLeft : '20px', paddingBottom: '0px', marginLeft: '0%', float : 'left' }}>
                                <h2 style={{ color: 'purple', fontSize : '35px' }} >FIRST FLOOR : </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="plant" className="section product mobile-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Carousel showArrows={true} autoFocus={true} autoPlay={true} >
                        {firstFloorStoresImagesList.map(url => (
                            <div>
                            <img loading="lazy"src={url} width="200" height="200" alt="img01" />
                            </div>
                            ))}
                    </Carousel>
                </div>
            </div>
            <div id="plant" className="section product web-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Splide options={ {
                            type : 'loop',
                            gap: '10px',
                            speed : 200,
                            perPage : 4,
                            perMove : 1,
                            easing : 'linear',
                            autoplay : true,
                            pauseOnHover : false,
                            resetProgress: false,
                            arrows: 'slider',
                        } }
                        hasSliderWrapper
                        
                        hasAutoplayProgress>
                        {firstFloorStoresImagesList.map(url => (
                           <SplideSlide>
                           <img loading="lazy"src={url} width="400" height="400" alt="img01" />
                          </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </div>
            <div id="plant" className="section product" style={{ paddingTop: '40px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingLeft : '20px', paddingBottom: '0px', marginLeft: '0%', float : 'left' }}>
                                <h2 style={{ color: 'purple', fontSize : '35px' }} >SECOND FLOOR : </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="plant" className="section product mobile-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Carousel showArrows={true} autoFocus={true} autoPlay={true} >
                        {secondFloorStoresImagesList.map(url => (
                            <div>
                            <img loading="lazy"src={url} width="200" height="200" alt="img01" />
                            </div>
                            ))}
                    </Carousel>
                </div>
            </div>
            <div id="plant" className="section product web-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Splide options={ {
                            type : 'loop',
                            gap: '10px',
                            speed : 200,
                            perPage : 4,
                            perMove : 1,
                            easing : 'linear',
                            autoplay : true,
                            pauseOnHover : false,
                            resetProgress: false,
                            arrows: 'slider',
                        } }
                        hasSliderWrapper
                        
                        hasAutoplayProgress>
                        {secondFloorStoresImagesList.map(url => (
                           <SplideSlide>
                           <img loading="lazy"src={url} width="400" height="400" alt="img01" />
                          </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </div>
            <div id="plant" className="section product" style={{ paddingTop: '40px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingLeft : '20px', paddingBottom: '0px', marginLeft: '0%', float : 'left' }}>
                                <h2 style={{ color: 'purple', fontSize : '35px' }} >THIRD FLOOR : </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="plant" className="section product mobile-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Carousel showArrows={true} autoFocus={true} autoPlay={true} >
                        {thirdFloorStoresImagesList.map(url => (
                            <div>
                            <img loading="lazy"src={url} width="200" height="200" alt="img01" />
                            </div>
                            ))}
                    </Carousel>
                </div>
            </div>
            <div id="plant" className="section product web-store-view" style={{ paddingTop: '0px' }}>
                <div className="container" >
                <Splide options={ {
                            type : 'loop',
                            gap: '10px',
                            speed : 200,
                            perPage : 4,
                            perMove : 1,
                            easing : 'linear',
                            autoplay : true,
                            pauseOnHover : false,
                            resetProgress: false,
                            arrows: 'slider',
                        } }
                        hasSliderWrapper
                        
                        hasAutoplayProgress>
                        {thirdFloorStoresImagesList.map(url => (
                           <SplideSlide>
                           <img loading="lazy"src={url} width="400" height="400" alt="img01" />
                          </SplideSlide>
                        ))}
                    </Splide>
                </div>
            </div>
        </>
    )

}

export default Stores




