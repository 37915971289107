import React from 'react'

const Loader = () => {
    return (
        <div id="loader" className="loader_bg" style={{ display: "none"}}>
            <div className="loader"><img loading="lazy"src="images/loading.gif" alt="#" /></div>
        </div>
    )
}

export default Loader
