import React, {Component} from 'react'
import Helmet from 'react-helmet'
import './css/About.css'
import axios from 'axios'
import Modal from '../Shared/Modal'
import { METADATA_PAGES } from '../MetaData'

const subscribeStyle = {
    borderTopRightRadius: '20px !important',
    borderBottomRightRadius: '20px !important',
    color: '#ffffff',
    paddingBottom: '10px',
    paddingTop: '10px',
    padding: '10px 12px',
    backgroundColor: 'purple'
}


const downloadButtonStyle = {
    fontWeight: 'bold',
    maxHieght: '41px',
    fontSize: '17px',
    backgroundColor : 'white',
    color: 'black'
}

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            email: '',
            filename: ''
        };
    }

    handlerChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    handleSubmit = e => {
        e.preventDefault();
        this.subscribe();
    }

    downnloadFile = (filename) => {
        fetch('https://cbd39.com/pdf/')
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('Something went wrong, please try agin.'));
    }

    subscribe = () => {
        var obj = {
            email: this.state.email,
            purpose: 'DOWNLOAD',
            filename : this.state.filename
        }
        axios.post("https://cbd39.com/api/savedata.php", obj)
            .then(response => {
                console.log(response);
                this.setState({
                    email: ''
                });
                localStorage.setItem('session_email', obj.email);
                this.downnloadFile(this.state.filename);
                this.modalClose();
            })
            .catch(err => {
                console.log(err);
                this.modalClose();
            });
    }

    modalOpen(filename) {
        if(localStorage.getItem("session_email")){
            this.setState({
                email : localStorage.getItem("session_email"),
                filename : filename
            });
            this.downnloadFile(filename);
        }
        else
        {
            this.setState({ modal: true, filename : filename });
        }
    }

    modalClose() {
        this.setState({
            modal: false
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{METADATA_PAGES.AboutUs.title}</title>
                    <meta title={METADATA_PAGES.AboutUs.title} name="description" content={METADATA_PAGES.AboutUs.content} />
                    <meta title={METADATA_PAGES.AboutUs.title} name="keywords" content={METADATA_PAGES.AboutUs.keywords} />
                </Helmet>
                <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
                    <span className="closebtn" onClick={() => this.modalClose()}>&times;</span>
                    <span style={{ fontSize: '35px' }} ><strong>Subscribe Us</strong></span><br />
                    <div className="input-group mb-3">
                        <input className="form-control" style={{ borderBottomLeftRadius: '20px !important', borderTopLeftRadius: '20px !important', padding: '3px 6px !important', fontSize: '15px' }}
                            type="text" placeholder="Enter your email" name="email" onChange={this.handlerChange} value={this.state.email} />
                        <div className="input-group-append">
                            <button style={subscribeStyle} onClick={this.handleSubmit} className="btn btn-primary" type="Subscribe">Subscribe</button>
                        </div>
                    </div>
                </Modal>
                <div className="section about">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="titlepage" style={{ paddingBottom: '0px' }} >
                                    <h2><strong className="black"> About Us</strong></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row" >
                            <div className="col-sm-12">
                                <div id="testimonial" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner" style={{ minHeight : '100px' }} >
                                        <div className="carousel-item active">
                                            <div className="titlepage">
                                                <div>
                                                    <img loading="lazy"width='100%' style={{ maxHeight: '600px' }} src={process.env.PUBLIC_URL + '/images/about_us.png'} alt="img" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="titlepage" style={{ textAlign:'justify' }} >
                                    <span><strong>CBD is a commercial Development in Faridabad that will represent the future of retail and Commercial Office spaces in the NCR. An
                                    innovative design which is all set to become Faridabad’s retail hotspot, CBD offers a wide range of facilities such as easy day shopping
                                    in large multi branded outlets, wide boulevards with coffee shops, lounges and street eating joints, wide pedestrian walk ways to
                                ensure leisurely shopping experience and aesthetic landscaping. In addition A Grade office spaces with multiple modules.</strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div id="main_slider" className="section carousel slide banner-main" style={{ backgroundColor: 'black', marginBottom: '100px' }} data-ride="carousel">
                        <div className="carousel-inner" style={{ minHeight : '100px' }}>
                            <div className="carousel-item active">
                                <div className="container">
                                    <div className="row aboutcard">
                                        <div className="col-xl-6 col-lg-7 col-md-7 col-sm-12">
                                            <h3 style={{ lineHeight: '55px' }}><strong style={{ textDecoration: 'underline', fontSize: '25px' }} >Details :- </strong></h3><br />
                                            <h3><strong>Developer :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>A.N Infrastructure & Developers (P) Limited</span></h3><br />
                                            <h3><strong>Project Type :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>Retail cum Office Space</span></h3><br />
                                            <h3><strong>Sector :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>Sector 39, SurajKund - Faridabad Road</span></h3><br />
                                            <h3><strong>Total Space :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}> 50000 sq.ft Retail, 98000 sq.ft. Office+ Retail Space</span></h3><br />
                                            <h3><strong>Land Area :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>2.143 Acres</span></h3><br />
                                            <h3><strong>Construction Allowed :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>7 Storey GF - FF - 2nd to 7th</span></h3><br />
                                            <h3><strong>Company Booking :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>Open, Booking Amount 15% of BSP</span></h3><br />
                                            <h3><strong>LOI/License :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}> Obtained from DTCP Haryana Govt</span></h3><br />
                                            <h3><strong>Possession :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>2020</span></h3><br />
                                            <h3><strong>Construction :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>7 Storey Structure Complete</span></h3><br />
                                            <h3><strong>Landmark :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>Near Taj Hotel ( Claridges Hotel ), SurajKund Road</span></h3><br />
                                            <h3><strong>Location :</strong>&nbsp;<span className="about-detail" style={{ fontSize: '15px', fontWeight: 'bold' }}>Adjacent " Express Sarovar Portico Hotels & Resorts "</span></h3><br />
                                            <h3 style={{ lineHeight: '0px' }}><strong>Feature :</strong></h3><br />
                                            <ol>
                                                <li> All Commercial Space facing the SurajKund road for maximum exposure. </li>
                                                <li> Close proximity to 5-Star Hotels & Residential Neighborhood.</li>
                                                <li> Excellent Connectivity with Gurgaon via new Gurgaon-Faridabad Highway.</li>
                                                <li> On Surajkund-Faridabad Highway (Opposite SurajKund Mela Parking).</li>
                                            </ol>
                                        </div>
                                        <div className="col-xl-6 col-lg-5 col-md-5 col-sm-12" style={{ paddingTop: '55px' }}>
                                            <div className="img-box">
                                                <figure><img loading="lazy"src="/images/dayview.jpg" alt="img" style={{ maxWidth: '100%' }} /></figure>
                                            </div>
                                            <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                                <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen('cbd-faridabad-shop-office-rate-list.pdf', e)}>Download</button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };
}

export default About
