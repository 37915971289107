import React from 'react'

const posterStyle = {
    marginLeft: '53%',
    paddingLeft: '0px',
    marginTop: '-30px'
}


const IndexSlider = () => {
    return (
        <>
            {/* <section > */}
            <div id="main_slider" className="banner-main" data-ride="carousel">
                <div className="carousel-inner" id="crousel-image" style={{ backgroundImage: `url(/images/dayview.jpg)`, backgroundSize: 'cover' }} >
                    <div className="container">
                        <div className="row marginii">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12" style={posterStyle}>
                                <div style={{ textAlign: 'center' }} className="carousel-caption" >
                                    <h1 style={{ fontSize: '40px', color: '#9a224a', paddingBottom: '0px' }}>Welcome to <strong className="color">CBD Mall</strong></h1>
                                </div>
                                <div className="carousel-caption index-slider-div" style={{  maxWidth : '32%', marginLeft: '39%' }}>
                                    <div>
                                        <img loading="lazy" src="/images/slider_banner.gif" height="8em" alt="img" style={{ height: '255px', width: '278px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </section> */}
        </>
    )
}

export default IndexSlider
