import React from 'react'

const WhatsAppWidget = () => {
        return (
            <>
            <a href="https://wa.me/919810437024" className="float_whatsapp" rel="noreferrer" alt="tag" target="_blank">
                <i className="fa fa-whatsapp my-float"></i>
                </a>
            </>
        );
}

export default WhatsAppWidget
