import React from 'react'
import { Link } from 'react-router-dom';

const Navigation = () => {
   return (
      <>
         {/* <header className="section"> */}
            <div className="header" style={{ paddingTop : '0px' }}>
               <div className="container" style={{marginTop : '5px'}}>
                  <div className="row" style={{ marginRight : '-40px' }} >
                     <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col logo_section">
                        <div className="full">
                           <div className="center-desk">
                              <div className="logo"> <a href="/"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/logo.png'} width="55" height="65" style={{height: 'relative'}} alt="logo" /></a> </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7" style={{ paddingLeft : '1px', paddingRight : '0px' }} >
                        <div className="menu-area mean-container" style={{ paddingTop: '7px' }} >
                        <div className="mean-bar">
                           <a href="#nav" className="meanmenu-reveal"><span></span><span></span><span></span></a><nav className="mean-nav">
                           <ul id="menu-link-area" className="menu-area-main" style={{ display: 'none' }}>
                              <li> <Link to="/">HOME</Link> </li>
                              <li> <Link to="/stores">Stores</Link></li>
                              <li> <Link to="/floor-plan">FLOOR PLAN</Link></li>
                              <li> <Link to="/about">ABOUT US</Link></li>
                              <li> <Link to="/contact">CONTACT</Link></li>
                           </ul>
                        </nav></div>
                           <div className="limit-box">
                           <div className="mean-push"></div>
                              <nav className="main-menu">
                                 <ul>
                                    <li> <Link to="/">HOME</Link> </li>
                                    <li> <Link to="/stores">Stores</Link></li>
                                    <li> <Link to="/floor-plan">FLOOR PLAN</Link></li>
                                    <li> <Link to="/about">ABOUT US</Link></li>
                                    <li> <Link to="/contact">CONTACT</Link></li>
                                 </ul>
                              </nav>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col logo_section" style={{ paddingTop : '15px'  }}>
                        <div id="social-media" className="full" style={{ paddingLeft : '40px' }} >
                           <div className="center-desk">
                              <div className="logo">
                              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/ashish.ashsih.100" style={{ padding: '0px 10px' }}>
                            <img loading="lazy" src={process.env.PUBLIC_URL + '/images/social-icon/fb_menu.png'} width="30" height="55" alt="img" />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://instagram.com/cbd039?igshid=ydhda259x3o6"  style={{ padding: '0px 10px' }} >
                            <img loading="lazy"src={process.env.PUBLIC_URL + '/images/social-icon/insta_menu.png'} width="32" height="55" alt="img" />
                            </a>
                            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/reena-shah-00692420a"  style={{ padding: '0px 10px' }} >
                            <img loading="lazy"src={process.env.PUBLIC_URL + '/images/social-icon/linkdin_menu.png'} width="28" height="55" alt="img" />
                            </a> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         {/* </header> */}
      </>
   );
};

export default Navigation
