import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import CopyRightFooter from './Shared/Footer/CopyRightFooter'
import Footer from './Shared/Footer/Footer'
import IndexSlider from './Shared/IndexSlider'
import Navigation from './Shared/Navigation'
import NotFound from './Shared/NotFound'
import Home from './Home/Home';
import PdfDownloadList from './PdfDownload/PdfDownloadList';
import Stores from './Stores/Stores';
import About from './About/About';
import Contact from './ContactUs/Contact';
import WhatsAppWidget from './Shared/WhatsAppWidget';
import Loader from './Shared/Loader';

class App extends Component {
    render() {
        return (
            <Router>
                <>
                    <Loader></Loader>
                    <Navigation></Navigation>
                    <IndexSlider />
                    <Switch>
                        <Route exact path="/" component={Home} ></Route>
                        <Route exact path="/floor-plan" component={PdfDownloadList} ></Route>
                        <Route exact path="/stores" component={Stores}></Route>
                        <Route exact path="/about" component={About}></Route>
                        <Route exact path="/contact" component={Contact}></Route>
                        <Route path="" component={NotFound} ></Route>
                    </Switch>
                    <WhatsAppWidget></WhatsAppWidget>
                    <Footer></Footer>
                    <CopyRightFooter></CopyRightFooter>
                </>
            </Router>
        )
    };
}

export default App
