// import react from 'react';

const NotFound = () => {
    return (
        <>
            <div id="plant" className="section product" style={{paddingTop: '50px'}} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingBottom : '50px', marginLeft : '0%' }}>
                                <h2 style={{ color: 'black' }} >NOT FOUND </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NotFound

