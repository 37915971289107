import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Modal from '../Shared/Modal'
import axios from 'axios'
import {METADATA_PAGES} from '../MetaData'

const pdfList = {
    groundFloor: 'cbd-floor-plan-ground-floor.pdf',
    firstFloor: 'cbd-floor-plan-first-floor.pdf',
    secondFloor: 'cbd-floor-plan-second-floor.pdf',
    thirdFloor: 'cbd-floor-plan-third-floor.pdf',
    fourthFloor: 'cbd-floor-plan-fourth-floor.pdf',
    fifthandsixthFloor: 'cbd-floor-plan-fifth-sixth-floor.pdf',
    commercialFloor: 'cbd-Commercial-Building.pdf',
    applicationformFloor: 'cbd-project-faridabad-application-form.pdf'
}
const subscribeStyle = {
    borderTopRightRadius: '20px !important',
    borderBottomRightRadius: '20px !important',
    color: '#ffffff',
    paddingBottom: '10px',
    paddingTop: '10px',
    padding: '10px 12px',
    backgroundColor: 'purple'
}


const downloadButtonStyle = {
    fontWeight: 'bold',
    maxHieght: '41px',
    fontSize: '17px',
    backgroundColor : 'purple'
}

class PdfDownloadList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            email: '',
            filename : ''
        };
    }

    handlerChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.subscribe();
    }

    downnloadFile = (filename) => {
        fetch('https://cbd39.com/pdf/')
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => alert('Something went wrong, please try agin.'));
    }

    subscribe = () => {
        var obj = {
            email: this.state.email,
            purpose: 'DOWNLOAD',
            filename : this.state.filename
        }
        axios.post("https://cbd39.com/api/savedata.php", obj)
            .then(response => {
                console.log(response);
                this.setState({
                    email: ''
                });
                localStorage.setItem('session_email', obj.email);
                this.downnloadFile(this.state.filename);
                this.modalClose();
            })
            .catch(err => {
                console.log(err);
                this.modalClose();
            });
    }

    modalOpen(filename) {
        if(localStorage.getItem("session_email")){
            this.setState({
                email : localStorage.getItem("session_email"),
                filename : filename
            });
            this.downnloadFile(filename);
        }
        else
        {
            this.setState({ modal: true, filename : filename });
        }
    }

    modalClose() {
        this.setState({
            modal: false
        });
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{METADATA_PAGES.FloorPlan.title}</title>
                    <meta title={METADATA_PAGES.FloorPlan.title} name="description" content={METADATA_PAGES.FloorPlan.content} />
                    <meta title={METADATA_PAGES.FloorPlan.title} name="keywords" content={METADATA_PAGES.FloorPlan.keywords} />
                </Helmet>
                <Modal show={this.state.modal} handleClose={e => this.modalClose(e)}>
                    <span className="closebtn" onClick={() => this.modalClose()}>&times;</span>
                    <span style={{ fontSize: '35px' }} ><strong>Subscribe Us</strong></span><br />
                    <div className="input-group mb-3">
                        <input className="form-control" style={{ borderBottomLeftRadius: '20px !important', borderTopLeftRadius: '20px !important', padding: '3px 6px !important', fontSize: '15px' }}
                            type="text" placeholder="Enter your email" name="email" onChange={this.handlerChange} value={this.state.email} />
                        <div className="input-group-append">
                            <button style={subscribeStyle} onClick={this.handleSubmit} className="btn btn-primary" type="Subscribe">Subscribe</button>
                        </div>
                    </div>
                </Modal>
                <div id="plant" className="section product" style={{ paddingTop: '40px', marginBottom: '5px' }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="titlepage" style={{ paddingBottom: '25px', marginLeft: '0%' }}>
                                    <h2 className="black" style={{ color: 'black' }}>FLOOR PLAN</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clothes_main section" style={{ paddingBottom: '100px', backgroundColor: 'white', paddingTop: '20px' }} >
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                <div className="sport_product" style={{ outline: '5px solid black' }} >
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em !important" alt="img" /></figure>
                                    <h4>Ground Floor Plan</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.groundFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                                <div className="sport_product" style={{ outline: '5px solid black' }} >
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em" alt="img" /></figure>
                                    <h4>First Floor Plan</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.firstFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                                <div className="sport_product" style={{ outline: '5px solid black' }}>
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em" alt="img" /></figure>
                                    <h4>Second Floor Plan</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.secondFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                <div className="sport_product" style={{ outline: '5px solid black' }}>
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em" alt="img" /></figure>
                                    <h4>Third Floor Plan</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.thirdFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                <div className="sport_product" style={{ outline: '5px solid black' }} >
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em !important" alt="img" /></figure>
                                    <h4>Fourth Floor Plan</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.fourthFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                                <div className="sport_product" style={{ outline: '5px solid black' }}>
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em" alt="img" /></figure>
                                    <h4>5th and 6th Floor Plan</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.fifthandsixthFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 ">
                                <div className="sport_product" style={{ outline: '5px solid black' }}>
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em" alt="img" /></figure>
                                    <h4>Commercial Building</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.commercialFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                <div className="sport_product" style={{ outline: '5px solid black' }}>
                                    <figure height="10em"><img loading="lazy"src={process.env.PUBLIC_URL + '/images/pdfthumbnail.jpg'} height="12em" alt="img" /></figure>
                                    <h4>Application Form</h4>
                                    <p style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <button type="button" className="btn btn-lg btn-primary floorplan-btn" style={downloadButtonStyle} onClick={(e) => this.modalOpen(pdfList.applicationformFloor, e)}>Download</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };
}


export default PdfDownloadList

