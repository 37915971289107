import React from 'react'

const HighLights = () => {
    return (
        <>
            <div id="plant" className="section product" style={{ paddingTop: '50px' }} >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage" style={{ paddingBottom: '50px', marginLeft: '0%' }}>
                                <h2 style={{ color: 'black', fontFamily: 'Assistant', fontWeight: 'bold' }} >HIGHLIGHTS</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section layout_padding padding-top_0">
                <div className="container" style={{ maxWidth: '100%' }}>
                    <div className="row" >
                        <div className="col-sm-12">
                            <div id="testimonial" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner" style={{ minHeight : '100px' }}>
                                    <div className="carousel-item active">
                                        <div className="titlepage">
                                            <div className="john">
                                                <img loading="lazy" width='100%' style={{ maxHeight: '600px' }} src={process.env.PUBLIC_URL + '/images/highlights/mass.png'} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '100px' }} >
                        <div className="col-sm-12">
                            <div id="testimonial" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner" style={{ minHeight : '100px' }}>
                                    <div className="carousel-item active">
                                        <div className="titlepage">
                                            <div className="john">
                                                <img loading="lazy" width='100%' style={{ maxHeight: '600px' }} src={process.env.PUBLIC_URL + '/images/highlights/mass_2.png'} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HighLights
