import React, { Component } from 'react'
import axios from 'axios'

class ContactForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name: '',
            email: '',
            phone: '',
            message: ''
        }
    }

    componentDidMount() {

    }
    handlerChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = e => {

        e.preventDefault();
        var obj = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
            purpose: "CONTACT"
        }
        document.getElementById("contanct_loader").style.display = "block";

        axios.post("https://cbd39.com/api/savedata.php", obj)
            .then(response => {
                console.log(response);
                this.setState({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
                localStorage.setItem('session_email', obj.email);
                document.getElementById("contanct-form-success").style.display = "block";
                setTimeout(() => {
                    document.getElementById("contanct-form-success").style.display = "none";
                }, 2000);
                document.getElementById("contanct_loader").style.display = "none";
            })
            .catch(err => {
                console.log(err);
                localStorage.setItem('session_email', obj.email);
                document.getElementById("contanct-form-error").style.display = "block";
                setTimeout(() => {
                    document.getElementById("contanct-form-error").style.display = "none";
                }, 2000);
                document.getElementById("contanct_loader").style.display = "none";
            });

    }

    render() {
        const { name, email, phone, message } = this.state;
        return (
            <>
                <form noValidate={this.isValid} onSubmit={this.handleSubmit} style={{ backgroundColor: 'white', padding: '0px' }} >
                    <div className="form-group">
                        <input type="text" className="form-control form-control-sm" placeholder="Name" name="name" value={name} onChange={this.handlerChange} required />
                        <label className="red" style={{ display: 'none' }} id="contact_form_name_error" >Name is Required *</label>
                    </div>
                    <div className="form-group">
                        <input type="email" className="form-control form-control-sm" placeholder="Email" name="email" value={email} onChange={this.handlerChange} required />
                        <label className="red" style={{ display: 'none' }} id="contact_form_email_error" >Email is Required *</label>
                        <label className="red" style={{ display: 'none' }} id="contact_form_email_error_invalid" >Email is Invalid *</label>
                    </div>
                    <div className="form-group">
                        <input type="phone" className="form-control form-control-sm" placeholder="Phone" name="phone" value={phone} onChange={this.handlerChange} required />
                        <label className="red" style={{ display: 'none' }} id="contact_form_phone_error" >Phone is Required *</label>
                        <label className="red" style={{ display: 'none' }} id="contact_form_phone_invalid" >Phone is Required *</label>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control form-control-sm" placeholder="Massage" name="message" value={message} onChange={this.handlerChange} required />
                        <label className="red" style={{ display: 'none' }} id="contact_form_message_error" >Message is Required *</label>
                    </div>
                    <div className="submit_btn">
                        <button type="submit" className="btn btn-primary contanct-submit-btn" style={{ background: 'purple' }} >Send</button>
                        <img loading="lazy"id="contanct_loader" style={{ display: 'none', float: 'right' }} src="images/loading.gif" width="100" height="100" alt="gif" />

                    </div>
                </form>
                <div className="alert alert-success" id="contanct-form-success" style={{ display: 'none' }} >
                    <strong>Success!</strong> Request Has been Made Successfully, Our Team will Contanct you shortly.
                </div>
                <div className="alert alert-warning" id="contanct-form-error" style={{ display: 'none' }} >
                    <strong>Error!</strong> There are something wrong with the server.
                </div>
            </>
        )
    }
}

export default ContactForm
