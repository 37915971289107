import React from 'react'
import Helmet from 'react-helmet'
import ContactForm from './ContactForm'
import { METADATA_PAGES } from '../MetaData'

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>{METADATA_PAGES.ContactUs.title}</title>
                <meta title={METADATA_PAGES.ContactUs.title} name="description" content={METADATA_PAGES.ContactUs.content} />
                <meta title={METADATA_PAGES.ContactUs.title} name="keywords" content={METADATA_PAGES.ContactUs.keywords} />
            </Helmet>
            <div id="plant" className="contact_us layout_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage">
                                <h2 style={{ textAlign: 'center',  marginTop: '45px' }}><strong style={{ color: '#111315' }}>Contact Us</strong></h2>
                                {/* <span style={{ textAlign : 'center'}}>available, but the majority have suffered alteration in some form, by injected randomised words which don't look even slightly believable</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact_us_2 layout_padding paddind_bottom_0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 ">
                            <div className="titlepage">
                                <h2 style={{ textAlign: 'center',  marginTop: '45px', fontSize : '40px' }}><strong style={{ color: '#111315' }}>ASK YOUR QUERIES !!!</strong></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                        </div>
                        <div className="col-md-6" style={{ paddingBottom : '50px' }} >
                            <div className="email_btn" style={{ maxHeight : '580px'}} >
                                <ContactForm></ContactForm>
                            </div>
                        </div>
                        <div className="col-md-3">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
