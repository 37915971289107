export const METADATA_PAGES = {
    Home : {
        title : `Welcome to CBD Mall Surajkund, Faridabad`,
        content : `CBD is a commercial Development in Faridabad that will represent the future of retail and Commercial Office spaces in the NCR. An innovative design, which is all set to become Faridabad’s retail hot spot, CBD offers a wide range of facilities such as easy day shopping in large multi branded outlets, wide boulevards with coffee shops, lounges and street eating joints, wide pedestrian walkways to ensure leisurely shopping experience and aesthetic landscaping.`,
        keywords : `mall near me , shopping mall near me , cbd mall Faridabad, property for sale near me , shop space for sale near me , faridabad mall , gym space for rent near me , office space for rent near me , commercial space for rent near me , small retail space for rent near me , restaurant space for rent near me .`
    },
    Store : {
        title : `Shops for Rent in Faridabad with affordable prices`,
        content : `There is 24 hours dedicated security available Separate electricity/water meter is provided for this property. It is under cctv surveillance. Full power backup.`,
        keywords : `mall near me , shopping mall near me , cbd mall Faridabad, property for sale near me , shop space for sale near me , faridabad mall , gym space for rent near me , office space for rent near me , commercial space for rent near me , small retail space for rent near me , restaurant space for rent near me .`
    },
    FloorPlan : {
        title : `Shopping Mall Plan & Views | Architectural drawings | CBD mall`,
        content : `Shopping Mall plans and views details free download. Basement plan (Shopping Mall). Shopping Mall. Ground Floor plan (Shopping Mall).PDF`,
        keywords : `shopping mall ground floor plan , cbd mall Faridabad, shopping mall floor plan pdf , shopping mall floor plan design , shopping mall floor plan pdf free download , small shopping mall design plan in india , commercial shopping complex plan `
    },
    AboutUs : {
        title : `About us | CBD Mall| Faridabad`,
        content : `CBD is a commercial Development in Faridabad that will represent the future of retail and Commercial Office spaces in the NCR.`,
        keywords : `about us page , cbd mall Faridabad, mall near me , shopping mall near me , property for sale near me , shop space for sale near me , faridabad mall , gym space for rent near me , office space for rent near me , commercial space for rent near me , small retail space for rent near me , restaurant space for rent near me .`
    },
    ContactUs : {
        title : `Contact us | CBD Mall| Faridabad`,
        content : `call us- +91 98104-37024, mail us to info@cbd39.com, Near Taj Hotel ( Claridges Hotel ), SurajKund Road, Sector 39, Faridabad Road`,
        keywords : `Contact us , cbd mall Faridabad , mall near me , shopping mall near me , property for sale near me , shop space for sale near me , faridabad mall , gym space for rent near me , office space for rent near me , commercial space for rent near me , small retail space for rent near me , restaurant space for rent near me .`
    }
}
